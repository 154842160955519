#app-container {
  padding-top: 82px;
  max-width: 920px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.logo-container {
  display: flex;
  justify-content: space-between;

  .logo-text {
    display: flex;
    align-items: center;
    font-size: small;
    font-weight: 500;
}
}

