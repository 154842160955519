#tracker {
  margin-left: 16px;

  .tracking-step {
    margin-left: 16px;
    border-radius: 2px;
    padding: 0 8px 24px 16px;

    &:first-child {
      padding-top: 16px;
    }

    .tracking-step-headline {
      font-size: 16px;
      font-weight: bold;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -23px;
        background-color: #43a047;
        width: 10px;
        height: 10px;
      }
    }

    .tracking-step-subheading {
      color: #5a5b6a;
    }

    &.gray-border {
      border-left: 2.5px dotted #b4b4bb;

      .tracking-step-headline {
        color: #5a5b6a;
        font-weight: 600;

        &::before {
          background-color: #b4b4bb;
        }
      }
    }

    &.green-border {
      border-left: 2.5px dotted #43a047;

      .tracking-step-headline {
        color: #19191d;
      }
    }
  }
}

.header-card {
  padding: 8px 16px !important;

  @media (max-width: 768px) {
    margin-top: 1rem;
  }

  &.header-card-delivered {
    .tracking-status-badge {
      background: #65b168;
      color: #132c14;
    }
  }

  &.header-card-cancelled {
    .tracking-status-badge {
      background: #f6655a;
      color: #43130f;
    }
  }
}

.timeline-container {
  margin-top: 1rem;
}

.tracker-heading {
  //text-transform: uppercase;
  .tracking-id-heading {
    text-transform: uppercase;
    //margin-bottom: 4px;
    font-weight: 600;
    font-size: 1.25rem;
  }

  .tracking-id-number {
    //margin-top: 0px;
    font-weight: 500;
    font-size: 0.9rem;
    //color: #5d5d5d;
  }

  .order-current-status {
    font-size: 1rem;
  }
}

.tracking-status-badge {
  background: #e5ae40;
  color: #463209;
  width: fit-content;
  border-radius: .25rem;
  padding: 0 .85rem;
}

.address-card {
  margin-top: 1rem;
  padding: 8px 16px !important;
  color: #19191d;
  display: flex;

  .business-name {
    font-weight: bold;
  }

  .business-detail {

  }
}

.store-address-container {
  flex: 1;
}

.delivery-address-container {
  flex: 1;
  border-left: 1.5px dotted #19191d;
  padding-left: 16px;
}

.rider-info {
  margin-top: 1rem;
  color: #19191d;
  padding: 8px 16px !important;
}

#tracking-map {
  margin-top: 1rem;
  width: 100%;
  height: 400px;
  margin-bottom: 2rem;
}

#enter-tracking-code {
  padding: 8px 16px !important;
}

#track-order-btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
